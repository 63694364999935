import React, { Component } from "react";
import { connect } from "react-redux";
import { getUser } from "../../../../shared/helpers/user-helper";
import { setEvent } from "../../../../firebaseConfig";

class Memberships extends Component {
  render() {
    const { profile, memberships, golferActive } = this.props;
    const fullName = [
      profile.prefix,
      profile.first_name,
      profile.middle_name,
      profile.last_name,
      profile.suffix,
    ];
    return (
      <div className="profile-page__head">
        <div className="p-row">
          <div className="l-col">
            <h2 className="profile-name">{fullName.join(" ")}</h2>
            <p className="profile-details">
              GHIN {this.props.trialGolfer ? "Trial " : ""}Number: {profile.id}
            </p>
          </div>
          {!this.props.trialGolfer ? (
            <div className="r-col">
              <div className="membership-container">
                <h4>{golferActive ? "MEMBERSHIPS" : "INACTIVE MEMBERSHIPS"}</h4>
                <div className="membership-list">
                  {memberships.map((membership) => (
                    <div
                      key={JSON.stringify(membership)}
                      className="membership-item"
                    >
                      <div className="membership-item__badge">
                        <img
                          src={membership.logo}
                          alt={membership.association_name}
                        />
                      </div>
                      <div className="membership-item__details">
                        <strong>{membership.association_name}</strong>
                        <span>{membership.club_name}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div
              className="r-col"
              style={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              <button
                className="btn fill cardinal"
                style={{
                  borderRadius: "20px 20px 20px 20px",
                  width: "45%",
                  marginTop: "10px",
                  height: "70%",
                  paddingLeft: "18px",
                  fontSize: "15px",
                  letterSpacing: "0.1px",
                }}
                onClick={() => {
                  const user = getUser();
                  setEvent(
                    user,
                    "Trial_Sign_Up_Click",
                    "Screen_Name",
                    "My_Profile"
                  );
                  window.open(
                    "https://getahandicap.usga.org/v2app/#/golfer-registration/sign-up?name=Trial"
                  );
                }}
              >
                SIGN UP FOR A HANDICAP INDEX
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authReducer, profileReducer }) => ({
  currentUser: authReducer.loginReducer.user,
  memberships: profileReducer.golferProfileFetchReducer.golferMemberships,
  golferActive: profileReducer.golferProfileFetchReducer.golferActive,
  trialGolfer: profileReducer.golferProfileFetchReducer.trialGolfer,
  // golfers: authReducer.loginReducer.golfers,
  // minors: authReducer.loginReducer.minors,
  // searchStatus: authReducer.searchGolferReducer.status,
  // searchGolfers: authReducer.searchGolferReducer.golfers,
});

export default connect(mapStateToProps, null)(Memberships);
