import React from "react";
import {
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  ListItemText,
  Chip,
  OutlinedInput,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Typography,
  Button,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const ChallengeHeader = ({
  challengeName,
  challengeStatus,
  daysLeft,
  currentUser,
  isSmallScreen,
  setModalOpen,
  handleFollowingChange,
  isFollowingChecked,
  handleStatusChange,
  selectedStatus,
  lastUpdated,
}) => {
  return (
    <div className="challenge-content">
      <Typography variant="overline" className="challenge-title">
        <h2>{challengeName}</h2>
      </Typography>
      <Typography variant="h4" className="challenge-leaderboard-title">
        Leaderboard
      </Typography>
      <div className="challenge-header">
        <div style={{ display: "flex", alignItems: "center" }}>
          {daysLeft >= 0 && (
            <AccessTimeIcon style={{ fontSize: "14px", marginTop: "1px" }} />
          )}
          <Typography
            variant="caption"
            className="challenge-caption"
            style={{ paddingTop: "3px", paddingLeft: "5px" }}
          >
            {daysLeft < 0
              ? "Challenge finished"
              : `Last updated ${lastUpdated} • ${daysLeft} days left`}
          </Typography>
        </div>
        {currentUser &&
          challengeStatus != "upcoming" &&
          (isSmallScreen ? (
            <Button
              className="challenge-filters-btn"
              onClick={() => setModalOpen(true)}
              sx={{
                textTransform: "none",
                color: "#00365F",
                fontWeight: 700,
              }}
            >
              Filter
            </Button>
          ) : (
            <div className="challenge-filter-container">
              <span className="challenge-filter-container-label">
                Filter by
              </span>
              <FormControl
                variant="outlined"
                size="small"
                className="challenge-filter"
              >
                <Select
                  displayEmpty
                  value=""
                  input={<OutlinedInput />}
                  renderValue={() => " "}
                >
                  <MenuItem sx={{ padding: 0 }} onClick={handleFollowingChange}>
                    <Checkbox checked={isFollowingChecked} />
                    <ListItemText primary="Following" />
                  </MenuItem>
                  <Box className="challenge-filter-label">By Status</Box>
                  <RadioGroup
                    onChange={(event) => handleStatusChange(event.target.value)}
                  >
                    <MenuItem sx={{ padding: 0 }}>
                      <FormControlLabel
                        value="all"
                        control={<Radio checked={selectedStatus == "all"} />}
                        label="All"
                        sx={{
                          width: "100%",
                          margin: 0,
                          paddingLeft: "5px",
                        }}
                      />
                    </MenuItem>
                    <MenuItem sx={{ padding: 0 }}>
                      <FormControlLabel
                        value="qualified"
                        control={
                          <Radio checked={selectedStatus == "qualified"} />
                        }
                        label="Qualified"
                        sx={{
                          width: "100%",
                          margin: 0,
                          paddingLeft: "5px",
                        }}
                      />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel
                        value="in_progress"
                        control={
                          <Radio checked={selectedStatus == "in_progress"} />
                        }
                        label="In-progress"
                      />
                    </MenuItem>
                  </RadioGroup>
                </Select>
              </FormControl>
            </div>
          ))}
      </div>

      <div className="row" style={{ margin: 0 }}>
        <div className="challenge-chips-container">
          {isFollowingChecked && (
            <Chip
              label={"Following"}
              onDelete={handleFollowingChange}
              className="challenge-chip"
            />
          )}
          {selectedStatus !== "all" && (
            <Chip
              label={
                selectedStatus == "qualified" ? "Qualified" : "In-progress"
              }
              onDelete={() => handleStatusChange("all")}
              className="challenge-chip"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChallengeHeader;
