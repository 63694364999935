import React, { Component, Fragment } from "react";
import TrialForm2 from "./TrialForm2";
import TrialForm from "./TrialForm";
import TrialThanks from "./TrialThanks";
import { routesContext } from "../../../../shared/routes/routes-context";
import PropTypes from "prop-types";
import { isUserLoggedIn } from "../../../../shared/helpers/user-helper";
import RegisterErrorModal from "../../../../shared/components/modals/RegisterErrorModal";
import GolferApi from "../../../../services/golfer-api";
import {
  login,
  userAcceptedTerms,
  userDeniedTerms,
  resetForm,
} from "../../actions";
import { completeLogout } from "../../actions/api-login-methods";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import { LOGIN_TITLE, LOGIN_DESC } from "../../../../shared/variables/meta-seo";

class Trial extends Component {
  static contextType = routesContext;
  constructor(props) {
    super(props);

    this.state = {
      displayTrialForm2: false,
      thanks: false,
      formData: [],
      formNextData: [],
      errorMessage: "",
      displayError: false,
      submitting: false,
    };
  }

  componentDidMount() {
    if (isUserLoggedIn()) {
      this.context.profile.navigate();
    }
  }

  submitFormData = (combinedFormData) => {
    this.setState({
      formData: combinedFormData,
      submitting: true,
    });
    GolferApi.createTrial(combinedFormData)
      .then((r) => {
        console.log(r);
        this.setState({ thanks: true });
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.response.data.errors,
          displayError: true,
        });
      })
      .finally(() => {
        this.setState({ submitting: false });
      });
  };

  onCloseModal = () => {
    this.setState({ displayError: false });
  };

  render() {
    const { login } = this.props;
    const { displayTrialForm2 } = this.state;
    const formComponent = displayTrialForm2 ? (
      <TrialForm2
        initialData={this.state.formData}
        oldData={this.state.formNextData}
        submitting={this.state.submitting}
        onSubmit={this.submitFormData}
        onGoBack={(data) => {
          this.setState({
            formNextData: { ...data },
            displayTrialForm2: false,
          });
        }}
      />
    ) : (
      <TrialForm
        login={login}
        oldData={this.state.formData}
        onSwitchForm={(data) => {
          this.setState({
            displayTrialForm2: true,
            formData: { ...data },
          });
        }}
      />
    );

    return (
      <Fragment>
        {this.state.thanks ? (
          <TrialThanks formData={this.state.formData} />
        ) : (
          <div>
            <Helmet>
              <title>{LOGIN_TITLE}</title>
              <meta name="description" content={LOGIN_DESC} />
            </Helmet>
            <section className="hero__section login trial__background">
              <div className="welcome__header">Registration</div>
              <div className="welcome__header_small">Start a Trial</div>
              <div
                className="hero__container"
                style={{ alignItems: "flex-start" }}
              >
                <div
                  className="hero__column registration_container"
                  style={{
                    "max-width": "500px",
                  }}
                >
                  {formComponent}
                </div>
              </div>
            </section>
          </div>
        )}
        <RegisterErrorModal
          isOpen={this.state.displayError}
          onAbort={this.onCloseModal}
          onConfirm={this.onCloseModal}
          validationMessage={this.state.errorMessage}
        />
      </Fragment>
    );
  }
}

Trial.propTypes = {
  location: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  userAcceptedTerms: PropTypes.func.isRequired,
  userDeniedTerms: PropTypes.func.isRequired,
  postStatus: PropTypes.string.isRequired,
  userStatus: PropTypes.string.isRequired,
};

const mapStateToProps = ({ authReducer }) => ({
  postStatus: authReducer.loginReducer.postStatus,
  error: authReducer.loginReducer.error,
  userStatus: authReducer.loginReducer.userStatus,
  currentUser: authReducer.loginReducer.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { login, completeLogout, userAcceptedTerms, userDeniedTerms, resetForm },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Trial);
