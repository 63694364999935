import React, { Component, Fragment } from "react";
import DefaultCustomSelect from "../../../../../shared/components/selects/DefaultCustomSelect";
import { Formik } from "formik";
import * as Yup from "yup";
import SubmitButtons from "./SubmitButtons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { patchMyProfile, toggleSecondaryAddress } from "../../../actions";
import jobStatus from "../../../../../shared/variables/job-status";
import YesNoModal from "../../../../../shared/components/modals/YesNoModal";
import DatePickerSelect from "./DatePickerSelect";
import {
  defaultAddressesFormValues,
  writeDateForApi,
} from "../../../../../shared/helpers/my-profile-helper";

const FIELD = "primary_address";
class EditAddressForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmDelete: false,
    };
  }

  validationPrimarySchema = Yup.object({
    country: Yup.string().required("Country is required"),
    street_1: Yup.string()
      .required("Street Address is required")
      .ensure() // Transforms undefined and null values to an empty string.
      .test("Only Empty?", "Street Address can't be blank", (value) => {
        return value.split(" ").join("").length !== 0;
      }),
    city: Yup.string()
      .required("City is required")
      .ensure()
      .test("Only Empty?", "City can't be blank", (value) => {
        return value.split(" ").join("").length !== 0;
      }),
    state: Yup.string()
      .required("State is required")
      .typeError("State is required"),
    zip: Yup.string()
      .required("Postal Code is required")
      .ensure()
      .test("Only Empty?", "Postal Code can't be blank", (value) => {
        return value.split(" ").join("").length !== 0;
      }),
  });

  validationSecondarySchema = Yup.object({
    country: Yup.string().required("Country is required"),
    street_1: Yup.string()
      .required("Street Address is required")
      .ensure()
      .test("Only Empty?", "Street Address can't be blank", (value) => {
        return value.split(" ").join("").length !== 0;
      }),
    city: Yup.string()
      .required("City is required")
      .ensure()
      .test("Only Empty?", "City can't be blank", (value) => {
        return value.split(" ").join("").length !== 0;
      }),
    state: Yup.string()
      .required("State is required")
      .typeError("State is required"),
    zip: Yup.string()
      .required("Postal Code is required")
      .ensure()
      .test("Only Empty?", "Postal Code can't be blank", (value) => {
        return value.split(" ").join("").length !== 0;
      }),
    secondary_country: Yup.string().required("Country is required"),
    secondary_street_1: Yup.string()
      .required("Street Address is required")
      .ensure()
      .test("Only Empty?", "Street Address can't be blank", (value) => {
        return value.split(" ").join("").length !== 0;
      }),
    secondary_city: Yup.string()
      .required("City is required")
      .ensure()
      .test("Only Empty?", "City can't be blank", (value) => {
        return value.split(" ").join("").length !== 0;
      }),
    secondary_state: Yup.string()
      .required("State is required")
      .typeError("State is required"),
    secondary_zip: Yup.string()
      .required("Postal Code is required")
      .ensure()
      .test("Only Empty?", "Postal Code can't be blank", (value) => {
        return value.split(" ").join("").length !== 0;
      }),
    secondary_active_from: Yup.string().required("Start Date is required"),
    secondary_active_to: Yup.string().required("End Date is required"),
  });

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { patchStatus, patchError } = nextProps;
    if (this.props.activeField === FIELD) {
      if (
        patchStatus !== this.props.patchStatus &&
        patchStatus === jobStatus.FAILED
      ) {
        this.props.onError(patchError);
      }

      if (
        patchStatus !== this.props.patchStatus &&
        patchStatus === jobStatus.SUCCESS
      ) {
        this.props.onSuccess();
        setTimeout(() => {
          this.formOptions.resetForm();
        }, 1000);
      }
    }
  }

  getCountriesOptions() {
    const { countries } = this.props;
    return countries.map((country) => ({
      value: country.code,
      label: country.name,
    }));
  }

  getStateOptions(country) {
    if (!country || (country && !!country.states)) return [];

    const { countries } = this.props;
    const countryState = countries.find(
      (c) => c.code === country.value || c.name === country.value
    );
    return countryState?.states?.map((state) => ({
      value: state.code,
      label: state.name,
    }));
  }

  onChangeCountry = (option) => {
    this.formOptions.setFieldValue("country", option);
    this.formOptions.setFieldValue("state", null);
  };

  onChangeSecondaryCountry = (option) => {
    this.formOptions.setFieldValue("secondary_country", option);
    this.formOptions.setFieldValue("secondary_state", null);
  };

  onChangeState = (option) => this.formOptions.setFieldValue("state", option);

  onChangeSecondaryState = (option) =>
    this.formOptions.setFieldValue("secondary_state", option);

  onResetSpecificFormFields(fields) {
    fields.forEach((f) => {
      if (["secondary_active_from", "secondary_active_to"].includes(f))
        this.formOptions.setFieldValue(f, "");
      else this.formOptions.setFieldValue(f, null);
    });
  }

  resetSecondaryAddressFields() {
    this.onResetSpecificFormFields([
      "secondary_country",
      "secondary_state",
      "secondary_street_1",
      "secondary_street_2",
      "secondary_city",
      "secondary_zip",
      "secondary_active_from",
      "secondary_active_to",
    ]);
  }

  onAddOrDeleteSecondary = () => {
    const { profile } = this.props;
    if (profile.hasSecondaryAddress) {
      this.setState({ showConfirmDelete: true });
    } else {
      this.resetSecondaryAddressFields();
      this.props.toggleSecondaryAddress(true);
    }
  };

  onDeleteSecondaryAddress = () => {
    this.setState({ showConfirmDelete: false });
    this.resetSecondaryAddressFields();
    this.props.toggleSecondaryAddress(false);
    const { profile } = this.props;
    this.props.patchMyProfile({
      club_id: profile.club_id,
      ghin: profile.id,
      data: {
        delete: "secondary",
      },
    });
  };

  onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    const { profile } = this.props;

    let data = {
      primary_address: {
        city: values.city,
        country: values.country.value,
        state: values.state.value,
        street_1: values.street_1,
        street_2: values.street_2,
        zip: values.zip,
      },
    };

    if (profile.hasSecondaryAddress) {
      data = {
        ...data,
        secondary_address: {
          city: values.secondary_city,
          country: values.secondary_country.value,
          state: values.secondary_state.value,
          street_1: values.secondary_street_1,
          street_2: values.secondary_street_2,
          zip: values.secondary_zip,
          active_from: writeDateForApi(values.secondary_active_from),
          active_to: writeDateForApi(values.secondary_active_to),
        },
      };
    }

    this.props.patchMyProfile({
      club_id: profile.club_id,
      ghin: profile.id,
      activeField: FIELD,
      data,
    });
  };

  renderSecondaryForm(options, required) {
    const countriesOptions = this.getCountriesOptions();
    const stateOptions = this.getStateOptions(options.values.secondary_country);

    return (
      <>
        <div className="row">
          <div className="col">
            <label>Country{required}</label>
            <DefaultCustomSelect
              isSearchable
              value={options.values.secondary_country}
              onChange={this.onChangeSecondaryCountry}
              options={countriesOptions}
              title="Select Country"
              placeholder="Select Country"
              className="default_select profile full_on_phone svg-color"
            />
            <span className="error">
              {options.touched.secondary_country
                ? options.errors.secondary_country
                : ""}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label>Street Address{required}</label>
            <input
              type="text"
              className="large2"
              value={options.values.secondary_street_1}
              id="secondary_street_1"
              name="secondary_street_1"
              onChange={options.handleChange}
              onBlur={options.handleBlur}
            />
            <span className="error">
              {options.touched.secondary_street_1
                ? options.errors.secondary_street_1
                : ""}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label>Unit, Suite, Apartament #</label>
            <input
              type="text"
              className="large2"
              value={options.values.secondary_street_2}
              id="secondary_street_2"
              name="secondary_street_2"
              onChange={options.handleChange}
              onBlur={options.handleBlur}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label>City{required}</label>
            <input
              type="text"
              className="large2"
              value={options.values.secondary_city}
              id="secondary_city"
              name="secondary_city"
              onChange={options.handleChange}
              onBlur={options.handleBlur}
            />
            <span className="error">
              {options.touched.secondary_city
                ? options.errors.secondary_city
                : ""}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label>State{required}</label>
            <DefaultCustomSelect
              isSearchable
              value={options.values.secondary_state}
              onChange={this.onChangeSecondaryState}
              options={stateOptions}
              title="Select State"
              placeholder="Select State"
              className="default_select profile full_on_phone svg-color"
            />
            <span className="error">
              {options.touched.secondary_state
                ? options.errors.secondary_state
                : ""}
            </span>
          </div>
          <div className="col">
            <label>Postal Code{required}</label>
            <input
              type="text"
              className="large2"
              value={options.values.secondary_zip}
              id="secondary_zip"
              name="secondary_zip"
              onChange={options.handleChange}
              onBlur={options.handleBlur}
            />
            <span className="error">
              {options.touched.secondary_zip
                ? options.errors.secondary_zip
                : ""}
            </span>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <label>Start Date{required}</label>
            <DatePickerSelect
              options={options}
              fieldName="secondary_active_from"
            />
            <span className="error">
              {options.touched.secondary_active_from
                ? options.errors.secondary_active_from
                : ""}
            </span>
          </div>
          <div className="col">
            <label>End Date{required}</label>
            <DatePickerSelect
              options={options}
              fieldName="secondary_active_to"
            />
            <span className="error">
              {options.touched.secondary_active_to
                ? options.errors.secondary_active_to
                : ""}
            </span>
          </div>
        </div>
      </>
    );
  }

  renderPrimaryForm(options, required) {
    const countriesOptions = this.getCountriesOptions();
    const stateOptions = this.getStateOptions(options.values.country);
    return (
      <div className="group">
        <h4 className="panel-heading">Primary Address</h4>
        <div className="row">
          <div className="col">
            <label>Country{required}</label>
            <DefaultCustomSelect
              isSearchable
              value={options.values.country}
              onChange={this.onChangeCountry}
              options={countriesOptions}
              title="Select Country"
              placeholder="Select Country"
              className="default_select profile full_on_phone svg-color"
              aria-label="Select Country"
            />
            <span className="error">
              {options.touched.country ? options.errors.country : ""}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label>Street Address{required}</label>
            <input
              type="text"
              className="large2"
              value={options.values.street_1}
              id="street_1"
              name="street_1"
              onChange={options.handleChange}
              onBlur={options.handleBlur}
              aria-label="Enter Adress 1"
            />
            <span className="error">
              {options.touched.street_1 ? options.errors.street_1 : ""}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label>Unit, Suite, Apartment #</label>
            <input
              type="text"
              className="large2"
              value={options.values.street_2}
              id="street_2"
              name="street_2"
              onChange={options.handleChange}
              onBlur={options.handleBlur}
              aria-label="Enter Adress 2"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label>City{required}</label>
            <input
              type="text"
              className="large2"
              value={options.values.city}
              id="city"
              name="city"
              onChange={options.handleChange}
              onBlur={options.handleBlur}
              aria-label="Enter City"
            />
            <span className="error">
              {options.touched.city ? options.errors.city : ""}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label>State{required}</label>
            <DefaultCustomSelect
              isSearchable
              value={options.values.state}
              onChange={this.onChangeState}
              options={stateOptions}
              title="Select State"
              placeholder="Select State"
              className="default_select profile full_on_phone svg-color"
              aria-label="Select State"
            />
            <span className="error">
              {options.touched.state ? options.errors.state : ""}
            </span>
          </div>
          <div className="col">
            <label>Postal Code{required}</label>
            <input
              type="text"
              className="large2"
              value={options.values.zip}
              id="zip"
              name="zip"
              onChange={options.handleChange}
              onBlur={options.handleBlur}
              aria-label="Enter Zip Code"
            />
            <span className="error">
              {options.touched.zip ? options.errors.zip : ""}
            </span>
          </div>
        </div>
      </div>
    );
  }

  renderForm(options) {
    const { profile } = this.props;
    const required = <span style={{ color: "red" }}> *</span>;
    return (
      <Fragment>
        {this.renderPrimaryForm(options, required, profile)}
        <div className="group">
          <div className="row justify-between">
            <div className="col auto align-center">
              <h4 className="panel-heading no-wrap no-margin">
                Secondary Address
              </h4>
            </div>
            <div className="col auto">
              <button
                onClick={this.onAddOrDeleteSecondary}
                className={`btn ${
                  profile.hasSecondaryAddress ? "delete" : "add"
                }`}
              >
                {profile.hasSecondaryAddress ? "Delete" : "Add"}
              </button>
            </div>
          </div>
          {profile.hasSecondaryAddress &&
            this.renderSecondaryForm(options, required)}
        </div>

        <SubmitButtons
          onSubmit={options.handleSubmit}
          canSubmit={options.isValid}
          status={
            this.props.patchStatus === jobStatus.PROCESSING &&
            this.props.activeField === FIELD
          }
          onCancel={this.props.onCancelEdit}
        />
      </Fragment>
    );
  }
  render() {
    const { profile, primaryAddress, secondaryAddress } = this.props;
    const { showConfirmDelete } = this.state;

    const initialValues = defaultAddressesFormValues(
      primaryAddress,
      secondaryAddress
    );
    const scheme = profile.hasSecondaryAddress
      ? this.validationSecondarySchema
      : this.validationPrimarySchema;
    return (
      <>
        <Formik
          // isInitialValid={() => scheme.isValidSync(initialValues)}
          onSubmit={this.onSubmit}
          validationSchema={scheme}
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={initialValues}
          enableReinitialize
        >
          {(options) => {
            this.formOptions = options;
            return this.renderForm(options);
          }}
        </Formik>
        <YesNoModal
          reversedOrder
          reversedHighlight
          onConfirm={this.onDeleteSecondaryAddress}
          onAbort={() => this.setState({ showConfirmDelete: false })}
          isOpen={showConfirmDelete}
          isYesVisible={true}
          isNoVisible={true}
          yesTitle="YES"
          noTitle="NO"
        >
          <p>Are you sure you want to remove this address ?</p>
        </YesNoModal>
      </>
    );
  }
}

const mapStateToProps = ({ myProfileReducer }) => ({
  patchError: myProfileReducer.patchProfileReducer.error,
  patchStatus: myProfileReducer.patchProfileReducer.status,
  activeField: myProfileReducer.patchProfileReducer.activeField,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ patchMyProfile, toggleSecondaryAddress }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditAddressForm);
