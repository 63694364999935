import React, { Component } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { overlayModalStyle } from "../../../shared/helpers/ui-helper";

export default class InactiveGolferModal extends Component {
  componentDidMount = () => Modal.setAppElement(this.props.appElementName);

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        className="modal-dialog"
        style={overlayModalStyle}
        contentLabel={"Inactive golfer"}
      >
        <div className="modal-content">
          <button className="modal_close" onClick={onClose} aria-label="Close">
            ✕
          </button>
          <div className="modal-body">
            <div className="content">
              <h3>{"Inactive golfer"}</h3>
              <p>
                {
                  "You must have an active Membership to view your GHIN Rewind. Please contact your Club or Association."
                }
              </p>
            </div>
            <div className="actions centered">
              {onClose}
              <button
                className="btn fill cardinal background-focus"
                onClick={onClose}
                aria-label="Ok"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

InactiveGolferModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
