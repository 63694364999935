import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoaderButton from "../../../../shared/components/buttons/LoaderButton";
import jobStatus from "../../../../shared/variables/job-status";
import GolferItem from "./Components/GolferItem";
import { routesContext } from "../../../../shared/routes/routes-context";
import YesNoModal from "../../../../shared/components/modals/YesNoModal";
import { selectGolfer, fetchMinors, loadMinorGolfer } from "../../actions";
import { completeLogout } from "../../actions/api-login-methods";
import DisplayStatusModal from "../common/DisplayStatusModal";
import Loader from "../../../../shared/components/loaders/Loader";

class ChangeGolfer extends Component {
  static contextType = routesContext;
  constructor(props) {
    super(props);
    this.state = {
      isInactiveWarningOpen: false,
      selectedId: props.currentUser?.ghin_number || undefined,
      selectedGolfer: props.currentUser,
      changeStatus: 0,
      changeStatusMessage: "",
    };
  }

  componentDidMount() {
    const { currentUser } = this.props;
    if (!currentUser) {
      this.context.login.navigate();
      return;
    }
    this.props.fetchMinors(this.props.guardianId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentUser } = nextProps;
    if (currentUser && currentUser !== this.props.currentUser) {
      this.onSuccessChangeGolfer(currentUser, this.props.currentUser);
    }
  }

  componentWillUnmount() {
    if (!this.props.currentUser) {
      this.props.completeLogout();
    }
  }

  onSuccessChangeGolfer = (currentUser, oldCurrentUser) => {
    if (oldCurrentUser) {
      this.setState({
        changeStatus: 2,
        changeStatusMessage: `Golfer changed to ${currentUser.player_name}`,
      });
    } else {
      this.onNavigateToHome();
    }
  };

  onNavigateToHome = () => this.context.profile.navigate();

  onChangeGolfer = () => {
    const { selectedGolfer, selectedId } = this.state;
    const { currentUser } = this.props;
    //same golfer
    if (currentUser?.ghin_number === selectedId) {
      if (currentUser.status == "Inactive")
        this.setState({ isInactiveWarningOpen: true });
      else this.context.profile.navigate();
      return;
    }

    if (selectedGolfer.isMinor) {
      if (selectedGolfer.golfer_status == "Active")
        this.props.loadMinorGolfer(selectedGolfer);
      else this.setState({ isInactiveWarningOpen: true });
    } else {
      if (selectedGolfer.status == "Active")
        this.props.selectGolfer(selectedGolfer);
      else this.setState({ isInactiveWarningOpen: true });
    }
  };

  onSelectGolfer = (selectedGolfer) => {
    this.setState({
      selectedId: selectedGolfer.id || selectedGolfer.ghin_number,
      selectedGolfer,
    });
  };

  onConfirmWarning = () => {
    const { selectedGolfer, selectedId } = this.state;
    const { currentUser } = this.props;
    if (currentUser?.ghin_number === selectedId)
      this.context.profile.navigate();
    if (selectedGolfer.isMinor) {
      this.props.loadMinorGolfer(selectedGolfer);
    } else {
      this.props.selectGolfer(selectedGolfer);
    }
  };

  renderGuardianGolfer() {
    const { golfers } = this.props;
    if (golfers && golfers.length > 0) {
      const status = golfers[0]["status"] == "Inactive" ? " (Inactive)" : "";
      return (
        <GolferItem
          golfer={golfers[0]}
          golferId={golfers[0].ghin_number}
          status={status}
          onSelect={this.onSelectGolfer}
          selectedId={this.state.selectedId}
        />
      );
    }
    return null;
  }

  renderActiveMinorsGolfers() {
    const { minors } = this.props;
    const activeGolfers = minors.filter(
      (golfer) => golfer.golfer_status === "Active"
    );
    return activeGolfers.map((golfer) => (
      <GolferItem
        key={JSON.stringify(golfer.id)}
        golfer={{ ...golfer, isMinor: true }}
        golferId={golfer.id}
        onSelect={this.onSelectGolfer}
        selectedId={this.state.selectedId}
      />
    ));
  }

  renderInactiveMinorsGolfers() {
    const { minors } = this.props;
    const inactiveGolfers = minors.filter(
      (golfer) => golfer.golfer_status === "Inactive"
    );
    return inactiveGolfers.map((golfer) => (
      <GolferItem
        key={JSON.stringify(golfer.id)}
        golfer={{ ...golfer, isMinor: true }}
        status={" (Inactive)"}
        golferId={golfer.id}
        onSelect={this.onSelectGolfer}
        selectedId={this.state.selectedId}
      />
    ));
  }

  renderContent() {
    const { postStatus } = this.props;
    return (
      <div className="box-panel center no-margins">
        <div className="select-golfer">
          <div className="golfer-item top">{this.renderGuardianGolfer()}</div>
          <div className="separator-label">MINOR ACCOUNTS</div>
          <div className="golfer-item">{this.renderActiveMinorsGolfers()}</div>
          <div className="golfer-item">
            {this.renderInactiveMinorsGolfers()}
          </div>

          <div className="bottom_action">
            <LoaderButton
              type="submit"
              className="btn fill cardinal"
              onClick={this.onChangeGolfer}
              disabled={this.state.selectedId === undefined}
              loading={String(postStatus === jobStatus.PROCESSING)}
            >
              Change Golfer
            </LoaderButton>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { changeStatus, changeStatusMessage } = this.state;
    const { fetchStatus } = this.props;
    return (
      <Fragment>
        <div className="page_container create-profile-page">
          <div className="box-content">
            <h1>Change Golfer</h1>
          </div>
          {fetchStatus !== jobStatus.SUCCESS ? (
            <Loader />
          ) : (
            this.renderContent()
          )}
        </div>
        <YesNoModal
          onConfirm={this.onConfirmWarning}
          onAbort={this.onConfirmWarning}
          isOpen={this.state.isInactiveWarningOpen}
          isYesVisible={true}
          isNoVisible={false}
          yesTitle="Ok"
        >
          <h3>Inactive Membership</h3>
          <p>
            Your membership is inactive so you are no longer able to access some
            of the features within GHIN.com. Please contact your Club or Golf
            Association for assistance.
          </p>
        </YesNoModal>
        <DisplayStatusModal
          ctaLabel="Ok"
          status={changeStatus}
          statusMessage={changeStatusMessage}
          onOk={this.onNavigateToHome}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ authReducer }) => ({
  currentUser: authReducer.loginReducer.user,
  golfers: authReducer.loginReducer.golfers,
  minors: authReducer.loginReducer.minors,
  fetchStatus: authReducer.fetchMinorsReducer.status,
  postStatus: authReducer.loginReducer.postStatus,
  guardianId: authReducer.loginReducer.guardianId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { selectGolfer, completeLogout, fetchMinors, loadMinorGolfer },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChangeGolfer);
