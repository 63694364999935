import React, { Component, Fragment } from "react";
import LoginForm from "./LoginForm";
import CreateTrial from "./CreateTrial";
import PropTypes from "prop-types";
import {
  login,
  userAcceptedTerms,
  userDeniedTerms,
  resetForm,
} from "../../actions";
import { completeLogout } from "../../actions/api-login-methods";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import jobStatus from "../../../../shared/variables/job-status";
import YesNoModal from "../../../../shared/components/modals/YesNoModal";
import UserStatus from "../../../../shared/variables/user-status";
import ChallengeUnavailableModal from "../../../../shared/components/modals/ChallengeUnavailableModal";
import Navigate from "../../../../shared/navigation/navigate";
import { querystringToObj } from "../../../../shared/helpers/querystring-helper";
import { routesContext } from "../../../../shared/routes/routes-context";
import { isUserLoggedIn } from "../../../../shared/helpers/user-helper";
import AcceptTermsModal from "./AcceptTermsModal";
import AuthErrorModal from "../common/AuthErrorModal";
import { Helmet } from "react-helmet";
import { LOGIN_TITLE, LOGIN_DESC } from "../../../../shared/variables/meta-seo";

class Login extends Component {
  static contextType = routesContext;

  constructor(props) {
    super(props);

    this.state = {
      isLoginFailedOpen: false,
      acceptTermsModalVisible: false,
      challengeUnavailableModalOpen: false,
      isInactiveWarningOpen: false,
      isArchivedWarningOpen: false,
    };
  }

  componentDidMount() {
    if (isUserLoggedIn()) {
      this.context.profile.navigate();
    } else {
      this.props.resetForm();
    }

    if (
      this.props.location.state &&
      this.props.location.state.redirectedFrom &&
      !sessionStorage.getItem("challengeModalShown")
    ) {
      this.setState({ challengeUnavailableModalOpen: true });
      sessionStorage.setItem("challengeModalShown", "true");
    }
  }

  navigateToSelectGolfer = () => this.context.selectGolfer.navigate();

  navigateToContent = () => {
    const queryParams = querystringToObj(this.props.location.search);

    if (queryParams.returnUrl) {
      Navigate.toPath(queryParams.returnUrl);
    } else {
      this.context.profile.navigate();
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { postStatus, userStatus, currentUser, isTrial } = nextProps;
    if (
      postStatus !== this.props.postStatus &&
      postStatus === jobStatus.FAILED
    ) {
      this.setState({ isLoginFailedOpen: true });
    }

    if (userStatus === UserStatus.WAIT_FOR_ACCEPT_TERMS) {
      this.setState({ acceptTermsModalVisible: true });
    }

    if (
      postStatus === jobStatus.SUCCESS &&
      userStatus === UserStatus.WAIT_FOR_SELECT_GOLFER
    ) {
      this.navigateToSelectGolfer();
    }

    if (userStatus === UserStatus.LOGGED_IN) {
      if (currentUser.status == "Active" || isTrial) this.navigateToContent();
      else if (currentUser.status == "Inactive")
        this.setState({
          isInactiveWarningOpen: true,
        });
      else if (currentUser.status == "Archived")
        this.setState({
          isArchivedWarningOpen: true,
        });
    }
  }

  logoutArchived = () => {
    console.log("logout");
    this.props.completeLogout().then(() => {
      Navigate.resetToPath(this.context.login.path);
    });
  };

  onCloseAcceptTermsModal = () =>
    this.setState(
      { acceptTermsModalVisible: false },
      this.props.userDeniedTerms
    );

  onAcceptTermsModal = () => {
    this.setState(
      { acceptTermsModalVisible: false },
      this.props.userAcceptedTerms(this.props.golfers, this.props.minors)
    );
  };

  render() {
    const { login, error, postStatus } = this.props;
    const {
      isLoginFailedOpen,
      acceptTermsModalVisible,
      isInactiveWarningOpen,
      isArchivedWarningOpen,
    } = this.state;

    return (
      <Fragment>
        <Helmet>
          <title>{LOGIN_TITLE}</title>
          <meta name="description" content={LOGIN_DESC} />
        </Helmet>
        <section className="hero__section login trial__background">
          <div className="welcome__header">Welcome to GHIN</div>
          <div className="hero__container" style={{ alignItems: "flex-start" }}>
            <div className="login_container">
              <div className="hero__column" style={{ flex: "1 1 33%" }}>
                <LoginForm login={login} postStatus={postStatus} />
              </div>
              <div className="separator hide-on-phone"></div>
              <div
                className="hero__column"
                style={{
                  marginTop: "40px",
                }}
              >
                <CreateTrial />
              </div>
            </div>
          </div>
          <AcceptTermsModal
            visible={acceptTermsModalVisible}
            onCancel={this.onCloseAcceptTermsModal}
            onAccept={this.onAcceptTermsModal}
          />
        </section>
        <AuthErrorModal
          visible={isLoginFailedOpen}
          onClose={() => this.setState({ isLoginFailedOpen: false })}
          onOk={() => this.setState({ isLoginFailedOpen: false })}
          errorBody={error}
        />
        <YesNoModal
          onConfirm={this.context.profile.navigate}
          onAbort={this.context.profile.navigate}
          isOpen={isInactiveWarningOpen}
          isYesVisible={true}
          isNoVisible={false}
          yesTitle="Ok"
        >
          <h3>Inactive Membership</h3>
          <p>
            Your membership is inactive so you are no longer able to access some
            of the features within GHIN.com. Please contact your Club or Golf
            Association for assistance.
          </p>
        </YesNoModal>
        <YesNoModal
          onConfirm={this.logoutArchived}
          onAbort={this.logoutArchived}
          isOpen={isArchivedWarningOpen}
          isYesVisible={true}
          isNoVisible={false}
          yesTitle="Ok"
        >
          <h3>Archived Membership</h3>
          <p>
            Your membership has been archived. Please contact your club or
            Association for assistance.
          </p>
        </YesNoModal>
        <ChallengeUnavailableModal
          isOpen={this.state.challengeUnavailableModalOpen}
          onConfirmModal={() =>
            this.setState({ challengeUnavailableModalOpen: false })
          }
          onCloseModal={() =>
            this.setState({ challengeUnavailableModalOpen: false })
          }
        />
      </Fragment>
    );
  }
}

Login.propTypes = {
  location: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  userAcceptedTerms: PropTypes.func.isRequired,
  userDeniedTerms: PropTypes.func.isRequired,
  postStatus: PropTypes.string.isRequired,
  userStatus: PropTypes.string.isRequired,
};

const mapStateToProps = ({ authReducer }) => ({
  postStatus: authReducer.loginReducer.postStatus,
  minors: authReducer.loginReducer.minors,
  golfers: authReducer.loginReducer.golfers,
  error: authReducer.loginReducer.error,
  userStatus: authReducer.loginReducer.userStatus,
  currentUser: authReducer.loginReducer.user,
  isTrial: authReducer.loginReducer.isTrial,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { login, completeLogout, userAcceptedTerms, userDeniedTerms, resetForm },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
