/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { overlayModalStyle } from "../../helpers/ui-helper";

class ChallengeUnavailableModal extends Component {
  componentDidMount = () => Modal.setAppElement(this.props.appElementName);

  render() {
    const { isOpen } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        style={overlayModalStyle}
        className="trial-modal-dialog max-height-380"
        contentLabel={"Modal"}
      >
        <div
          className="trial-modal-content"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            position: "relative"
          }}
        >
          <button
            className="trial-modal_close"
            onClick={this.props.onCloseModal}
            aria-label="Close"
          >
            ✕
          </button>
          {/* Text container centered vertically */}
          <div
            className="trial-modal-body"
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "0 20px" // optional: adjust horizontal padding if needed
            }}
          >
            <h3
              style={{
                fontSize: "22px",
                fontWeight: "530",
                margin: "0 0 10px 0"
              }}
            >
              {this.props.title}
            </h3>
            <p
              style={{
                fontSize: "23px",
                margin: 0,
                fontWeight: "400",
              }}
            >
              GHIN Challenges is currently only available on the mobile app.
              
            </p>
            <br></br>
            <p
              style={{
                fontSize: "23px",
                margin: 0,
              }}
            >
            To join a Challenge, please click on the shared link from your mobile device or post a score to the participating course from your GHIN app.
          </p>
          </div>
          {/* Actions container fixed at bottom */}
          <div
            className="actions centered"
            style={{
              textAlign: "center",
              paddingBottom: "30px"
            }}
          >
            <button
              className="lnk white color-focus"
              onClick={this.props.onCloseModal}
              style={{
                textAlign: "center",
                fontSize: "17px",
                color: "dodgerblue",
                fontWeight: "600",
                background: "white",
                border: "none",
                fontFamily: "arial, sans-serif",
                cursor: "pointer"
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

ChallengeUnavailableModal.defaultProps = {
  reversedOrder: false,
  reversedHighlight: false,
  yesTitle: "YES",
  isYesVisible: true,
  noTitle: "NO",
  isNoVisible: true,
  onAbort: () => {},
  onConfirm: () => {},
  appElementName: "body"
};

ChallengeUnavailableModal.propTypes = {
  isOpen: PropTypes.bool.isRequired
};

export default ChallengeUnavailableModal;
