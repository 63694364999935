import React, { Component, Fragment } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import TagManager from "react-gtm-module";
import "./assets/styles/main.css";
import { isUserLoggedIn } from "./shared/helpers/user-helper";
import NavigationMiddleware from "./shared/navigation/navigation-middleware";
import { routes, routesArray } from "./shared/routes/index";
import DefaultLayout from "./shared/components/layouts/DefaultLayout";
import groupBy from "lodash/groupBy";
import SmartBannerWrapper from "./shared/components/banners/SmartBannerWrapper";
import BackTopButton from "./shared/components/buttons/BackTopButton";
import Challenge from "./screens/challenges/Challenge";
import { isMobile } from "react-device-detect";

class App extends Component {
  constructor(props) {
    super(props);
    if (window.location.pathname.includes("challenges"))
      this.mobileStoreRedirect();
    NavigationMiddleware.apply();
    NavigationMiddleware.handleNavigation(window.location);
  }

  componentDidMount() {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID,
    };
    TagManager.initialize(tagManagerArgs);
  }

  mobileStoreRedirect = () => {
    const navigationType =
      window.performance.getEntriesByType("navigation")[0].type;
    if (navigationType !== "navigate") return;

    let link = "";
    if (!isMobile) {
      sessionStorage.removeItem("challengeModalShown");
      this.props.history.push("/", { redirectedFrom: "challenges" });
      return;
    } else {
      // Detect whether the user is on iOS or Android
      var isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
      var isAndroid = /Android/i.test(navigator.userAgent);

      // Set the correct store link
      if (isIOS) {
        link = "https://itunes.apple.com/us/app/ghin-mobile/id491796218?mt=8";
      } else if (isAndroid) {
        link =
          "https://play.google.com/store/apps/details?id=com.advancedmobile.android.ghin&amp;hl=en_US";
      } else {
        return;
      }
    }

    // If mobile -> Redirect to App Store or Google Play
    // If web -> redirect to ghin.com
    window.location = link;
  };

  render() {
    return (
      <Fragment>
        <SmartBannerWrapper />
        {this.renderRootRoutes()}
        <BackTopButton />
      </Fragment>
    );
  }

  renderRootRoutes = () => {
    const rootRoutes = routesArray
      .filter((x) => x.rootPath)
      .map((r) => ({
        ...r,
        layout: r.layout || DefaultLayout,
      }));

    const directRoutes = routesArray
      .filter((x) => x.directRoute)
      .map((r) => ({
        ...r,
        layout: r.layout || DefaultLayout,
      }));

    const grouped = Object.values(groupBy(rootRoutes, (r) => r.layout));
    const direct = Object.values(groupBy(directRoutes, (r) => r.layout));

    return (
      <Switch>
        <Route
          exact
          path="/"
          render={({ location }) => {
            const { redirectedFrom } = location.state || {};
            return (
              <Redirect
                to={{
                  pathname: this.defaultRoute(),
                  state: { redirectedFrom },
                }}
              />
            );
          }}
        />
        <Route path="/leaderboard" render={() => <Challenge />} />
        {grouped.map((routes, key) => {
          const Layout = routes[0].layout;
          return (
            <Route
              key={key}
              path={this.getCombinedPaths(routes)}
              component={() => <Layout routes={routes} />}
            />
          );
        })}
        {direct.map((route, key) => {
          const Layout = route[0].layout;
          return (
            <Route
              key={key}
              path={route.path}
              component={() => <Layout routes={route} />}
            />
          );
        })}
      </Switch>
    );
  };

  defaultRoute = () =>
    isUserLoggedIn() ? routes.profile.path : routes.login.path;

  getCombinedPaths = (routes) =>
    `/(${routes.map((r) => r.path.replace("/", "")).join("|")})`;
}

export default withRouter(App);
